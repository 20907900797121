import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/blog/blogs-details.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Agility is a fun, exciting and rewarding activity for dogs. It's also one of the best training tools available to dog owners. Dogs and handlers work as teams, with the handler helping the dog to navigate the obstacles within the correct order. Agility can be done for fun, but there's also a competitive side to the sport should you and your dog with to pursue that, too!`}</p>
    <h2>{`Is my dog a good fit for agility training?`}</h2>
    <p>{`In the competitive scene, you'll likely to see a lot more Border Collies, Jack Russel Terriers, Australian Shepherds, Kelpies, Poodles and Corgis than most other breeds. Don't let that deter you, though! As far as recreational agility training goes, most breeds of dogs can participate. That being said, you most likely don't want to start agility training with very young puppies, older dogs, or dogs with health conditions. You should ask your veterinarian if your dog is healthy enough to start agility training.`}</p>
    <h2>{`Benefits for you and your dog`}</h2>
    <p>{`Agility training has a lot of benefits for both you and your dog! Training will help keep your dog in healthy fitness and can help energetic dogs burn through a lot of pent-up energy. On your end, you'll be moving about the agility course with your dog and you may be shocked at how much of a sweat you can work up, too! Lastly, the time spent with your dog is one of the best benefits there is. They'll appreciate the chance to spend time with you, to work towards a goal, and will love the occasional treat as a reward, too!`}</p>
    <h2>{`Tips to get started`}</h2>
    <p>{`One of the best ways to get started is by attending a dog agility training class. A quick google search for "Agility training class" or "Agility training camp" will help you find local opportunties to get started training with your dog. Additionally, these camps or classes are nice because they're usually lead by an experienced dog trainer who can help ensure that you and your pet are practicing healthy, sustainable form while training.`}</p>
    <p>{`For many of us, there's a good chance that we don't have an agility class or camp near us. In this case, you can often buy or build entry level agility equipment and set it up in your yard. Many different obstacles can be bought (or built!) in a collapsible or storeable form to help avoid taking up too much space, too.`}</p>
    <h2>{`Basic agility moves`}</h2>
    <p>{`The basic agility moves for dogs are actually quite simple and things that most dogs are already probably doing on their own. Mainly, dogs will be running, jumping, carefully walking, and weaving. The key to success in agility is practicing which moves are needed for which obstacle.`}</p>
    <p>{`If your dog is having issues performing any of these moves, especially running or jumping, you should contact your veterinarian right away to schedule an exam. If a dog is not in condition to run or jump but is continually asked to do so, they may injure themselves.`}</p>
    <h2>{`Basic agility equipment`}</h2>
    <p>{`Most agility courses will come with a slew of equipment ready to use, but make sure to start with beginners equipment at the start of your and your dog's training journey. You'll want to stick to low jump panels or jump cones, tunnels, and weave polls until your dog gets comfortable.`}</p>
    <p>{`If you do start with weave polls, it will likely be a good exercise in patience for you, too. They're not dangerous and they generally don't scare dogs, but performing them correctly takes a lot of brainpower and practice.`}</p>
    <p>{`Its important to note that a lot of obstacles can be scary for dogs during their first few attempts. Never force your dog into a tunnel or up a walkway and instead support them slowly with encouragement. You can even show them how its done! If an experienced trainer is in the training area, you may want to ask them for help or advice, too!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      